<template>
    <div>
        <!-- logo -->
        <!-- main -->
        
        <div class="mian">
            
        <Card />
        </div>
        <!-- 这里是页脚 -->
        <Footer />
    </div>
</template>

<script>
import Header from '../components/hearder.vue'
import Footer from '../components/footer.vue'
import Card from '../components/card.vue'
import Img from '../components/img.vue'
import{get} from '../network/request'
export default {
    data() {
    return {
        articledata:''
    }
    
    },
    created(){
        
    },
    mounted() {
    },
    methods: {
      
    },
    components:{
        Header,
        Footer,
        Card,
        Img,
    }
}
</script>

<style lang="less" scoped>
// .mian{
//     // isplay: flex;
//     // justify-content: center;
// }
</style>