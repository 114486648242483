<template>
  <div>
      <el-image class="img" :src="url"></el-image>
  </div>
</template>

<script>
export default {
    data() {
        return {
            url: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        }
    }
}
</script>

<style scoped>
.img {
    width: 100%;
    height: 400px;
}
</style>